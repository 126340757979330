import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';
import {
    BILLPAY_INIT_ERROR,
    PAYMENT_ERROR,
    BILLPAY_PAYMENT_SUCCESS,
    FETCH_CARD_LIST_SUCCESS,
    FETCH_CARD_LIST_FAILURE,
    PAYPAL_PAYMENT_INIT_ERROR,
    PAYPAL_PAYMENT_SUCCESS,
    VISACHECKOUT_INIT_ERROR,
    VISACHECKOUT_PAYMENT_ERROR,
    BILLPAY_CARD_ERROR,
    VISACHECKOUT_PAYMENT_SUCCESS,
    PAYPAL_PAYMENT_INIT_DONE,
    BRAINTREE_CC_VALIDATION_SUCCESS,
    SAVED_CARD_AUTORECHARGE
} from '../../adapters/constants';
import {
    LOCK_PAYMENT_STATE,
    PAYMENT_INIT_DONE,
    BRAINTREE_INIT_DONE,
    BRAINTREE_DEVICE_DATA,
    BILLPAY_HOSTED_FIELD_INITIALISED,
    PAYMENT_INIT_ERROR,
    ERROR_TYPE_NULLIFY,
    RESET_PAYMENT_ERRORS,
    UPDATE_PAYMENT_METHOD,
    ANALYTICS_PAYMENT_METHOD,
    SAVING_CARD_OPTION,
    HIDE_LOADING_INDICATOR,
    SHOW_LOADING_INDICATOR,
    SET_AUTO_RECHARGE,
    RESET_AUTO_RECHARGE,
    FETCH_SURCHARGE_SUCCESS,
    FETCH_SURCHARGE_FAILURE,
    FETCH_SURCHARGE_LOADING,
    RESET_SURCHARGE_RATES,
    STORE_BRAINTREE_VALIDATED_DATA,
    SET_IS_SELECTED_DEBIT_CARD,
    REQUEST_CANCELLED
} from '../actions';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false, formProcessed: false, lockFields: false, isPaymentInProgress: false, paymentFieldsInitialised: false, isSurchargeInProgress: false },
    locale: {},
    errors: { hasError: false, errorCode: null, hasServerError: false, failedAttempts: 0 },
    hostedFields: { initialised: false, ccInitialised: false },
    braintree: {},
    selectedPaymentType: '',
    selectedAnalyticsPaymentType: '',
    savingCardOption: false,
    autoRecharge: true,
    autoRechargeCardData: { brainTreeNonceData: null, savedCardData: null },
    isSurchargeProcessed: false,
    isSurchargeAPIDown: false,
    validatedBraintreeData: {},
    isDebitCardFromSurcharge: null,
    isDebitCardFromBRNTreeORSavedCard: null
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case PAYMENT_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true, tokenReceived: true },

                locale: action.data
            };
        case UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                loading: { ...state.loading, isPaymentInProgress: true },
                selectedPaymentType: action.data
            };
        case ANALYTICS_PAYMENT_METHOD:
            return {
                ...state,
                loading: { ...state.loading, isPaymentInProgress: true },
                selectedAnalyticsPaymentType: action.data
            };
        case SAVING_CARD_OPTION:
            return {
                ...state,
                loading: { ...state.loading, isPaymentInProgress: true },
                savingCardOption: action.data
            };
        case BRAINTREE_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true, tokenReceived: true },

                braintree: { ...state.braintree, token: action.data.token },
                autoRechargeCardData: { ...state.autoRechargeCardData, brainTreeNonceData: null, savedCardData: null }
            };

        case BRAINTREE_DEVICE_DATA:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true, tokenReceived: true },

                braintree: { ...state.braintree, token: action.data.token, deviceData: action.data.deviceData }
            };
        case BILLPAY_HOSTED_FIELD_INITIALISED:
            return {
                ...state,
                hostedFields: { ...state.hostedFields, ccInitialised: action.data }
            };
        case PAYPAL_PAYMENT_INIT_DONE:
            return {
                ...state,
                hostedFields: { ...state.hostedFields, initialised: true }
            };
        case PAYMENT_INIT_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: true,
                    errorCode: action.errorCode,
                    hasServerError: action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST
                },
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case ERROR_TYPE_NULLIFY:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: false,
                    errorCode: undefined
                },
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case PAYPAL_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true }
            };
        case VISACHECKOUT_PAYMENT_SUCCESS:
        case BILLPAY_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, isPaymentInProgress: false }
            };

        case VISACHECKOUT_INIT_ERROR:
        case BILLPAY_INIT_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: true,
                    errorCode: errorCodeMeta.PAYMENT_UNEXPECTED_ERROR
                },
                loading: {
                    ...state.loading,
                    lockFields: action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST
                }
            };
        case PAYPAL_PAYMENT_INIT_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: true,
                    errorCode: errorCodeMeta.PAYMENT_UNEXPECTED_ERROR
                },
                loading: {
                    ...state.loading,
                    lockFields: action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST,
                    isPaymentInProgress: false
                }
            };
        case BILLPAY_CARD_ERROR: {
            const hasErrorVar = true;
            const errorCodeVar = errorCodeMeta.PAYMENT_UNEXPECTED_ERROR;
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: hasErrorVar,
                    errorCode: errorCodeVar,
                    hasServerError: action.error.code === errorCodeMeta.SERVER_ERROR || action.error.code === errorCodeMeta.BAD_REQUEST
                },
                loading: {
                    ...state.loading,
                    lockFields: action.error.code === errorCodeMeta.SERVER_ERROR || action.error.code === errorCodeMeta.BAD_REQUEST,
                    isPaymentInProgress: false
                }
            };
        }
        case BRAINTREE_CC_VALIDATION_SUCCESS: {
            return {
                ...state,
                autoRechargeCardData: { ...state.autoRechargeCardData, brainTreeNonceData: action.paymentData, savedCardData: null }
            };
        }
        case SAVED_CARD_AUTORECHARGE: {
            return {
                ...state,
                autoRechargeCardData: { ...state.autoRechargeCardData, brainTreeNonceData: null, savedCardData: action.savedCardData }
            };
        }
        case PAYMENT_ERROR: {
            let {
                errors: { failedAttempts }
            } = state;
            failedAttempts += 1;
            const errCode = failedAttempts >= 3 ? errorCodeMeta.PAYMENT_UNEXPECTED_ERROR : action.errorCode;
            if (failedAttempts >= 3) {
                addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Customer has 3 unsuccessfule payment failure attempts');
            }
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: true,
                    errorCode: errCode,
                    failedAttempts,
                    errorBody: action.errorBody,
                    errorHeading: action.errorHeading
                },
                loading: {
                    ...state.loading,
                    isPaymentInProgress: false,
                    lockFields: failedAttempts >= 3
                }
            };
        }
        case VISACHECKOUT_PAYMENT_ERROR: {
            let {
                errors: { failedAttempts }
            } = state;
            const hasServerError = action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST;
            failedAttempts += 1;
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError: true,
                    errorCode: action.errorCode,
                    hasServerError,
                    failedAttempts
                },
                loading: {
                    ...state.loading,
                    lockFields: failedAttempts >= 3 || hasServerError
                }
            };
        }
        case LOCK_PAYMENT_STATE:
            return {
                ...state,
                loading: { ...state.loading, lockFields: true }
            };
        case RESET_PAYMENT_ERRORS:
            return {
                ...state,
                errors: { ...state.errors, hasError: false, errorCode: null, hasServerError: false }
            };

        case FETCH_CARD_LIST_SUCCESS:
            return {
                ...state,
                savedCardList: { ...state.savedCardList, savedCards: action.data }
            };

        case FETCH_CARD_LIST_FAILURE:
            return {
                ...state,
                savedCardList: { ...state.savedCardList, savedCards: { data: [] } }
            };
        case HIDE_LOADING_INDICATOR:
            return {
                ...state,
                loading: { ...state.loading, paymentFieldsInitialised: true }
            };
        case SHOW_LOADING_INDICATOR:
            return {
                ...state,
                loading: { ...state.loading, paymentFieldsInitialised: false }
            };
        case SET_AUTO_RECHARGE:
            return {
                ...state,
                autoRecharge: action.value,
                autoRechargeCardData: { ...state.autoRechargeCardData, brainTreeNonceData: null, savedCardData: null }
            };
        case RESET_AUTO_RECHARGE:
            return {
                ...state,
                autoRechargeCardData: { ...state.autoRechargeCardData, brainTreeNonceData: null, savedCardData: null }
            };
        case FETCH_SURCHARGE_LOADING:
            return {
                ...state,
                loading: { ...state.loading, isSurchargeInProgress: true },
            };
        case FETCH_SURCHARGE_SUCCESS:
            let processed = true;
            if(action.data.paymentMethod && action.data.paymentMethod?.type === 'PAYPAL'){
                processed = false;
            }
            return {
                ...state,
                surcharge: action.data.surcharges,
                isDebitCardFromSurcharge: action.data.paymentMethod.debit?.toLowerCase() !== 'no',
                loading: { ...state.loading, isSurchargeInProgress: false },
                isSurchargeProcessed: processed,
                isSurchargeAPIDown: false
            };
        case RESET_SURCHARGE_RATES:
            return {
                ...state,
                surcharge: [],
                loading: { ...state.loading, isSurchargeInProgress: false },
                isSurchargeProcessed: false,
                isSurchargeAPIDown: false
                };
        case FETCH_SURCHARGE_FAILURE:
            return {
                ...state,
                surcharge: action.data.surcharges,
                loading: { ...state.loading, isSurchargeInProgress: false },
                isSurchargeProcessed: true,
                isSurchargeAPIDown: true
            };
        case SET_IS_SELECTED_DEBIT_CARD:
            return {
                ...state,
                isDebitCardFromBRNTreeORSavedCard: action?.isSelectedDebitCard !== 'no'
            };   
        case STORE_BRAINTREE_VALIDATED_DATA:
            return {
                ...state,
                validatedBraintreeData: action.paymentData
            };
        default:
            return state;
    }
};

export default reducer;

export function errorMessageNullify() {
    return { type: ERROR_TYPE_NULLIFY };
}

/**
 * Returns dipatch object when page is initialized
 * @param {any} data
 */
export const moduleInitialised = (data) => {
    return { type: PAYMENT_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 * @export
 * @param {any} httpStatus
 */
export function moduleInitialisationError(httpStatus) {
    return { type: PAYMENT_INIT_ERROR, data: httpStatus };
}

// Action Creators
/**
 * Fetches AEM authorable content
 * @param {any} [dataFactory=client.fetchLocale]
 */
// export const fetchAemData = (dataFactory = client.fetchLocale) => {
//     const resourcePath = 'payment';
//     return dispatch => {
//         dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
//     };
// };

/**
 * Lock persisting form state
 *
 * @export
 * @returns
 */
export function lockState() {
    return { type: LOCK_PAYMENT_STATE };
}

/**
 * return dispatch object to
 * resets payment errors
 *
 * @export
 * @returns
 */
export function resetErrors() {
    return { type: RESET_PAYMENT_ERRORS };
}

export const getBranintreeAuthToken = (fetcher = client.fetchBraintreeAuthorizationToken) => {
    return (dispatch) => {
        return fetcher(dispatch, braintreeTokenSuccess, braintreeTokenFailure);
    };
};

export const braintreeTokenSuccess = (tokenDetails) => {
    const { hasError, errorCode, data } = processBraintreeTokenData(tokenDetails);
    if (hasError) {
        return braintreeTokenFailure(errorCode);
    }
    return {
        type: BRAINTREE_INIT_DONE,
        data
    };
};

export const processBraintreeTokenData = (tokenData) => {
    let errorCode = '';
    if (tokenData.status.toLowerCase() !== 'success') {
        if (
            ['BTNONCE-EX-TOKENISATION', 'BTNONCE-EX-NETWORKERR', 'BTNONCE-EX-OTHER', 'PTT-EX-400', 'PTT-EX-401', 'PTT-EX-403', 'PTT-EX-422', 'PTT-EX-500'].indexOf(tokenData.status) !== -1 ||
            tokenData.status.includes('PTT-EX-')
        ) {
            errorCode = errorCodeMeta.PAYMENT_UNEXPECTED_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: tokenData.data };
};

export const braintreeTokenFailure = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 404:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { type: PAYMENT_INIT_ERROR, errorCode };
};

// export function hideLoadingIndicator() {
//     return { type: HIDE_LOADING_INDICATOR };
// }

export function setAutoRecharge(value) {
    return {
        type: SET_AUTO_RECHARGE,
        value
    };
}

export const resetSurchargeRates = () => {
    return (dispatch) => {
        dispatch({ type: RESET_SURCHARGE_RATES});
    };
};

export const getSurchargeRates = (payload, cancelToken) => {
    return (dispatch) => {
        dispatch({type: FETCH_SURCHARGE_LOADING})
            return client.fetchSurchargeRates(dispatch, surchargeFetchSuccess, surchargeFetchFailure, { data:payload }, undefined, cancelToken);
    };
};

export const surchargeFetchSuccess = (surchargeData) => {
    const { hasError, errorCode, data } = processSurchargeData(surchargeData);
    return (dispatch) => {
        if (hasError) {
            dispatch(surchargeFetchFailure(errorCode, data));
        } else {
            dispatch({
                type: FETCH_SURCHARGE_SUCCESS,
                data,
            });
        }
    };
};

const processSurchargeData = (surchargeData) => {
    let errorCode = '';
    if (surchargeData.status === "SURC-EX") {
        errorCode = errorCodeMeta.SERVER_ERROR;
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: surchargeData.data };
};

export const surchargeFetchFailure = (httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 400:
            errorCode = errorCodeMeta.BAD_REQUEST;
            break;
        case 404:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 405:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return (dispatch) => {
        if(errorCode === REQUEST_CANCELLED) {
            dispatch(resetSurchargeRates());
        } else {
            dispatch({ type: FETCH_SURCHARGE_FAILURE, errorCode, data });
        }
    };
};