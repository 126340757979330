import './payment.css';
import React, { Component, Fragment } from 'react';
import { MessageInline, SegmentedView, TextStyle } from '@able/react';
import { Billpay, PaypalButton } from '../../components';
import SelectedRechargeDetails from './components/selected-recharge-card';
import store from '../../store';
import {
    FATAL_ERROR,
    PAYMENT_UNEXPECTED_ERROR,
    SUBMIT_CART_FATAL_REFUND,
    UPDATE_CART_FATAL_ERROR,
    FRAUD_DECLINED_ERROR,
    SUBMIT_CART_FATAL,
    CARD_PAYMENT_REFUND_SUCCESS,
    PAYPAL_PAYMENT_REFUND_SUCCESS,
    CARD_PAYMENT_REFUND_FAILURE,
    PAYPAL_PAYMENT_REFUND_FAILURE,
    NETWORK_FAILURE,
} from '../../adapters/errorCode';
import FullPageError from '../../components/FullPageError/FullPageError';
import { getCurrentRouteName, focusOnHeading, getAbleSpriteSheet } from '../../adapters/utils';
import { updateStepperCustomData } from '../../components/Progress/module';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import AutoRecharge from './components/auto-recharge';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import { redirectToMyBoost } from '../../adapters/esim-utils';
import SurchareRatesModal from '../../common/components/surchargeRatesModal/SurchargeRatesModal';

const paymentOptionsTabId = 'payment-options-tabs';
const ableSpriteSheet = getAbleSpriteSheet();

export default class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = { activePaymentTab: 'credit/debit card', paymentOptionEnabled: true, modalOpen: false, surchargeTable: [] };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.tabs = [];
        // To enable disable payment details based on auto recharge value
        this.shouldRenderTabContent = this.shouldRenderTabContent.bind(this);
    }

    componentDidMount() {
        const { actions: { getBranintreeAuthToken } = {} } = this.props;
        getBranintreeAuthToken();
        // addCartDataLayer(data);
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        focusOnHeading();

        // request and reserve msisdn for esim flow
        const { requestEsimMsisdn } = this.props.actions;
        const {
            uiState: { isEsimActivation, selectedActivationFlow },
            esimData: { hasCompletedEsimMsisdnRequest = false },
            serviceNumber: { starterCredit }
        } = this.props.appData;
        // const isPortingFlow = selectedActivationFlow === 'portNumber';
        isEsimActivation && !hasCompletedEsimMsisdnRequest && requestEsimMsisdn();

        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: 6,
            isLastStepCompleted: false
        };
        store.dispatch(updateStepperCustomData(stepperData));

        if (this.state.paymentOptionEnabled && starterCredit === 0) {
            const tabElement = document.querySelector(`#${paymentOptionsTabId} [role="tablist"]`);
            tabElement?.addEventListener('click', this.handleTabChange);
            tabElement?.addEventListener('keydown', this.handleTabChange);
        }
    }

    componentWillUnmount() {
        const tabElement = document.querySelector(`#${paymentOptionsTabId} [role="tablist"]`);
        tabElement?.removeEventListener('click', this.handleTabChange);
        tabElement?.removeEventListener('keydown', this.handleTabChange);
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen })
    }

    render() {
        let actualAmount;
        const state = store.getState();
        const { portingNumber = '' } = state.welcome;
        const newOfferSelected = state.offerDetails.selectedOffer ? state.offerDetails.selectedOffer : {};
        const { starterCredit } = this.props.appData.serviceNumber;
        const { msisdn } = state.app.appData;
        const { updateCart: { data: { cartTotalPrice = [] } = {} } = {} } = this.props.appData;
        const {
            errors = {},
            loading: { lockFields = false }
        } = this.props;

        const { autoRecharge, isDebitCardFromSurcharge, isSurchargeAPIDown, isDebitCardFromBRNTreeORSavedCard, savedCardList: { savedCards: { data: cardList = [] } = {} } = {} } = state.payment;
        const { undoRechargeAndActivate, activateSim } = this.props.actions;
        const {
            uiState: { loggedIn = false, appLock, requestInProgress = false, errors: cartError, selectedActivationFlow, isEsimActivation },
            loading: { activationInProgress }
        } = this.props.appData;
        const { hasRequestMsisdnError } = this.props.appData.esimData;
        const isPortingFlow = selectedActivationFlow === 'portNumber';
        const serviceNumber = isPortingFlow ? portingNumber : msisdn;
        const showLiveChat = appLock;
        const isDebitCard = isDebitCardFromSurcharge || isDebitCardFromBRNTreeORSavedCard;
        const { deviceData: { simVersion = '' } } = this.props;
        if (cartTotalPrice.length !== 0) {
            const { price: { dutyFreeAmount: { value = '' } = {} } = {} } = cartTotalPrice[0];
            actualAmount = value;
        }
        let hasfatalError = false;
        let hasunexpectedError = false;
        let errorProps = '';
        let context = '';
        let priceRise = false;
        priceRise = simVersion === 'PRE_PRICE_RISE';
        const noPaymentForValueSim = priceRise && starterCredit !== 0;
        const paymentHeader = starterCredit ? 'Review & confirm' : 'Review & payment';
        const starterCreditText = starterCredit ? 'Almost done! Check these details are correct then activate your SIM.' : '';
        const paymentOptionText = starterCredit && loggedIn && cardList.length !== 0 ? 'Choose a payment method' : 'Enter your card details';
        const { surchargeTable } = state.offerDetails || [];
        const { surchargeTableError } = state.offerDetails;
        if (errors && (errors.hasError || errors.errorCode) && (!cartError || (cartError && !cartError.hasError))) {
            if (errors.errorCode === FATAL_ERROR || errors.errorCode === NETWORK_FAILURE) {
                hasfatalError = true;
                errorProps = {
                    errorText: errors.errorHeading ? errors.errorHeading : 'Something went wrong',
                    errorMessage: errors.errorBody ? errors.errorBody : 'We’re unable to process the request at this time.'
                };
            } else if (errors.errorCode === PAYMENT_UNEXPECTED_ERROR) {
                hasunexpectedError = true;
                errorProps = {
                    errorText: errors.errorHeading ? errors.errorHeading : 'Unable to process request',
                    errorMessage: errors.errorBody ? errors.errorBody : 'We’re unable to process your payment at this time. You can still activate your SIM and recharge later.'
                };
            }
        } else if (cartError && (cartError.hasError || cartError.errorCode)) {
            if (cartError.errorCode === UPDATE_CART_FATAL_ERROR || cartError.errorCode === FATAL_ERROR) {
                hasfatalError = true;
                errorProps = {
                    errorText: 'Something went wrong',
                    errorMessage: 'We’re unable to process the request at this time. Please try again later.'
                };
            } else if (cartError.errorCode === FRAUD_DECLINED_ERROR) {
                hasfatalError = true;
                errorProps = {
                    errorText: 'Something went wrong',
                    errorMessage: 'We’re unable to process the request at this time.'
                };
            }
            else if (cartError.errorCode === CARD_PAYMENT_REFUND_SUCCESS || cartError.errorCode === PAYPAL_PAYMENT_REFUND_SUCCESS || cartError.errorCode === SUBMIT_CART_FATAL) {
                hasfatalError = true;
                if (isEsimActivation) {
                    errorProps = {
                        errorText: 'Something went wrong',
                        errorMessage: 'We’re unable to process your request at this time. To activate your SIM you’ll need to start again.',
                        errorMessage1: 'Any amount charged for this activation will be automatically refunded to your payment method.',
                        isEsimActivation
                    };
                }
                else {
                    errorProps = {
                        errorText: 'Something went wrong',
                        errorMessage: 'We’re unable to process your activation at this time.  Please try again later.',
                        errorMessage1: 'Any amount charged will be reimbursed if applicable.',
                        context: 'Customer has tried to activate, recharge has failed and they have been refunded'
                    };
                }
            } else if (cartError.errorCode === CARD_PAYMENT_REFUND_FAILURE || cartError.errorCode === PAYPAL_PAYMENT_REFUND_FAILURE || cartError.errorCode === SUBMIT_CART_FATAL_REFUND) {
                const { PaymentId = '' } = cartError;
                hasfatalError = true;
                if (this.state.activePaymentTab === 'paypal') {
                    context = `Customer has tried to activate, recharge has failed and Paypal refund has failed Payment ID: ${PaymentId}`;
                } else {
                    context = `Customer has tried to activate, recharge has failed and card refund has failed Payment ID: ${PaymentId}`;
                }
                if (isEsimActivation) {
                    errorProps = {
                        errorText: 'Something went wrong',
                        errorMessage: 'We’re unable to process your request at this time. To activate your SIM you’ll need to start again.',
                        errorMessage1: `Any amount charged for this activation will be reimbursed. Message our support team and quote your payment ID ${PaymentId} to start the refund process.`,
                        context,
                        isEsimActivation
                    };
                } else {
                    errorProps = {
                        context,
                        errorText: 'Something went wrong',
                        errorMessage: 'We’re unable to process your activation at this time.',
                        errorMessage1: `Please message our support team and quote your Payment ID ${PaymentId} to complete your reimbursement.`
                    };
                }
            }
        }
        if (!lockFields && !hasunexpectedError && !hasfatalError && !hasRequestMsisdnError) {
            return (
                <div className="container ppa-payment-block">
                    <GlobalBanner onPage="payments" className="infobanner-payments" />
                    <TextStyle tabIndex="-1" element="h1" alias="HeadingA">
                        {paymentHeader}
                    </TextStyle>
                    {starterCredit !== 0 && (
                        <TextStyle alias="TextBodyShort" className="mt-4">
                            {starterCreditText}
                        </TextStyle>
                    )}
                    <div className="row no-gutters">
                        <div className="col-md-4 offset-md-1 col-sm-12 order-md-12 mb-5 payment-margin-card">
                            <SelectedRechargeDetails {...this.props} actualAmount={actualAmount} plan={newOfferSelected} msisdn={serviceNumber} toggleModal={this.toggleModal} />
                        </div>
                        <div className="col-md-6 col-sm-12 order-md-1 mt-4 payment-activate-form">
                            {starterCredit !== 0 && !priceRise && (
                                <React.Fragment>
                                    <AutoRecharge
                                        shouldRenderTabContent={this.shouldRenderTabContent}
                                        {...this.props}
                                        disableARCheckbox={activationInProgress}
                                        autoRecharge={autoRecharge}
                                        actualAmount={actualAmount}
                                        plan={newOfferSelected}
                                        shouldRenderTabContentDefaultValue={this.state.paymentOptionEnabled}
                                        starterCredit={starterCredit}
                                        simVersion={simVersion}
                                        isDebitCard={isDebitCard}
                                        isSurchargeAPIDown={isSurchargeAPIDown}
                                    />
                                    {this.state.paymentOptionEnabled && (
                                        <React.Fragment>
                                            <TextStyle alias="HeadingD" element="h2" className="payment-margin-card">{paymentOptionText}</TextStyle>
                                            <TextStyle alias="TextBodyShort" className="mt-2">We’ll use this card for future auto recharge payments.</TextStyle>
                                            {this.renderBillpayCCForm(actualAmount)}
                                        </React.Fragment>)}
                                </React.Fragment>
                            )}

                            {starterCredit === 0 && (
                                <React.Fragment>
                                    <TextStyle element="h2" className="page-subheading" alias="HeadingC">Choose a payment method</TextStyle>
                                    <SegmentedView
                                        className="mt-3"
                                        fullWidthCategoryList
                                        id={paymentOptionsTabId}
                                        ariaLabel='Select a payment method'
                                        categoryListItems={[{
                                            title: 'Credit/debit card',
                                            panelContent: () => this.renderBillpayCCForm(actualAmount)
                                        },
                                        {
                                            title: 'Paypal',
                                            panelContent: () => this.renderPaypal(actualAmount, surchargeTable)
                                        }
                                        ]} />
                                </React.Fragment>
                            )}

                            {(!this.state.paymentOptionEnabled || noPaymentForValueSim) && (
                                <SpinningButton
                                    variant="HighEmphasis"
                                    onSubmit={() => activateSim()}
                                    stylingClass="CreditCardPayment-submit-button"
                                    isDisabled={activationInProgress}
                                    isLoading={activationInProgress}
                                    buttonText="Activate SIM"
                                />
                            )}
                        </div>
                    </div>
                    <SurchareRatesModal toggleModal={this.toggleModal} modalOpen={this.state.modalOpen} surchargeTable={surchargeTable} surchargeTableError={surchargeTableError}/>
                </div>
            );
        } else if (hasfatalError || lockFields || hasunexpectedError || hasRequestMsisdnError) {
            return (
                <Fragment>
                    <div className="container ppa-payment-block">
                        <GlobalBanner onPage="payments" className="infobanner-payments-error" />
                        {isEsimActivation ? (
                            (cartError &&
                                (cartError.errorCode === CARD_PAYMENT_REFUND_SUCCESS ||
                                    cartError.errorCode === PAYPAL_PAYMENT_REFUND_SUCCESS ||
                                    cartError.errorCode === CARD_PAYMENT_REFUND_FAILURE ||
                                    cartError.errorCode === PAYPAL_PAYMENT_REFUND_FAILURE))
                                ? (
                                    <FullPageError {...errorProps} />
                                ) : (
                                    redirectToMyBoost()
                                )
                        ) : (
                            <div className="activate-class">
                                <FullPageError {...errorProps} />
                                {(lockFields || hasunexpectedError) && !cartError && !isEsimActivation && (
                                    <div className="activate-button-div w-100">
                                        {addDataLayerEventInfo('error', 'skipRecharge', '', 'Payment failed. Continue to activate sim without recharge')}
                                        <SpinningButton
                                            className="activate-button"
                                            variant="HighEmphasis"
                                            isDisabled={requestInProgress || activationInProgress}
                                            isLoading={requestInProgress || activationInProgress}
                                            onSubmit={() => undoRechargeAndActivate()}
                                            buttonText="Activate SIM"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Fragment>
            );
        }
        return null;
    }

    renderBillpayCCForm = (actualAmount, surchargeTable) => {
        const { getSurchargeRates, resetSurchargeRates } = this.props.actions;
        const { token = '' } = this.props.braintree || {};
        const { msisdn } = this.props.appData.serviceNumber.packageData;
        return token !== '' && <Billpay amount={actualAmount} serviceId={msisdn} surchargeTable={surchargeTable} 
            toggleModal={this.toggleModal} modalOpen={this.state.modalOpen} getSurchargeRates={getSurchargeRates} resetSurchargeRates={resetSurchargeRates}/>;
    }

    renderPaypal = (actualAmount, surchargeTable) => {
        const { getSurchargeRates, resetSurchargeRates } = this.props.actions;
        const { token = '' } = this.props.braintree || {};
        const { msisdn } = this.props.appData.serviceNumber.packageData;
        return token !== '' && <PaypalButton amount={actualAmount} serviceId={msisdn} surchargeTable={surchargeTable} getSurchargeRates={getSurchargeRates} resetSurchargeRates={resetSurchargeRates}/>;
    }

    handleTabChange(e) {
        if (
            e.type === 'click' ||
            (e.type === 'keydown' && (e.keyCode === 13 || e.keyCode === 32)) // press "enter" or "space"
        ) {
            const activeTab = e.target?.textContent?.toLowerCase();
            if (activeTab && activeTab !== this.state.activePaymentTab) {
                this.props.actions.errorMessageNullify();
                this.setState({ activePaymentTab: activeTab });
            }
        }
    }

    shouldRenderTabContent(value) {
        this.setState({ paymentOptionEnabled: value });
    }
}
