import React, { Fragment, useState } from 'react';
import './auto-recharge.css';
import AUTORECHARGE_LOCALE from '../../../assets/locale/autorecharge-locale';
import LearnMoreModal from '../../../components/payment/LearnMoreModal';
import { getAbleSpriteSheet, getAREnabledAmountDescription } from '../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { ActionButton, Checkbox, MessageSection, ModalContent, TextStyle, useToggle } from '@able/react';
import Table from '../../../components/Table';
import PAYMENT_PAGE_CONSTANTS from '../constants';
import autorechargePicto from '../../../assets/img/AR_Sml-red.svg';

const ableSpriteSheet = getAbleSpriteSheet();

const { priceComparisonTableConfig } = PAYMENT_PAGE_CONSTANTS;

const AutoRecharge = (props) => {

    const [isPlanModalVisible, togglePlanModal] = useToggle();

    const { title, para, checkbox, specialOffer } = AUTORECHARGE_LOCALE;
    const { actualAmount, shouldRenderTabContent, plan, shouldRenderTabContentDefaultValue, starterCredit = 0, simVersion } = props;
    const value = actualAmount || starterCredit;
    let { autoRecharge, surcharge = [], isDebitCard, isSurchargeAPIDown } = props;

    const [isAutoRechargeChecked, setIsAutoRechargeChecked] = useState(autoRecharge);
    const checkValue = isAutoRechargeChecked ? 'active' : 'inactive';
    const price = plan.price;
    const num = plan.num;
    const discount = plan.discount;
    const endDate = plan.endDate;
    const includedBonus = plan.includedBonus;
    const autoRechargePrice = plan.autoRechargePrice;
    const autoRechargePercentage = plan.autoRechargePercentage;
    const autoRechargeBonusData = plan.autoRechargeBonusData;
    const discountedPrice = plan.discountedPrice;
    const period = plan.period;

    if (isAutoRechargeChecked !== shouldRenderTabContentDefaultValue) {
        shouldRenderTabContent && shouldRenderTabContent(isAutoRechargeChecked);
    }
    let priceRise = false;
    priceRise = simVersion === 'PRE_PRICE_RISE';
    let autoRechargeDescription = '';
    if (isAutoRechargeChecked) {
        if (starterCredit === 0) {
            autoRechargeDescription = getAREnabledAmountDescription({
                isDebitCard,
                plan,
                surcharge,
                isSurchargeAPIDown,
                para: 'Your payment details will be saved and automatically charged '
            });
        } else {
            autoRechargeDescription = `Your payment details will be saved and automatically charged every ${period}. ${!isDebitCard && isSurchargeAPIDown ? `We’re unable to include the credit card processing fee this time, but your next auto recharge will include it. You can view this amount by signing into My Boost, or in the SMS or email reminder.` : ` Check the total auto recharge amount after entering your payment details.`}`;
        }
    }

    addDataLayerEventInfo('impression', 'auto recharge module', `checkbox : ${checkValue}`, title);

    const toggleAutoRecharge = () => {
        if (props.disableARCheckbox) {
            return;
        }
        setIsAutoRechargeChecked(!isAutoRechargeChecked);

        if (!isAutoRechargeChecked) {
            addDataLayerEventInfo('click', 'auto recharge module', 'checkbox : active', title);
        } else {
            addDataLayerEventInfo('click', 'auto recharge module', 'checkbox : inactive', title);
        }

        // To get the props change on the parent component
        shouldRenderTabContent && shouldRenderTabContent(!isAutoRechargeChecked);
        props.actions.setAutoRecharge(!isAutoRechargeChecked);
    };

    const newTabForRechargeInfo = () => {
        const href = 'https://news.boost.com.au/latest-plans';
        window.open(href, '_blank');
    };

    const planTableData = priceComparisonTableConfig.tableData.TPPCP;

    return (
        <div className="payment-auto-recharge mt-5">
            <TextStyle element="h3" alias="HeadingC" className="auto-title">{title}</TextStyle>
            <TextStyle alias="TextBodyShort mbxxl" className="mt-3">{para} <LearnMoreModal /></TextStyle>
            {(autoRechargePercentage || autoRechargePrice || autoRechargeBonusData) && (
                <div className="autorecharge-specialoffer mt-4">
                    <p className="specialoffer-text">Special offer</p>
                    {autoRechargePercentage && (
                        <div>
                            <p className="specialoffer-title">
                                Get {autoRechargePercentage} off your next {num} {specialOffer.para}
                            </p>
                            <p className="specialoffer-description">
                                {specialOffer.discountDetails} {endDate}
                            </p>
                        </div>
                    )}
                    {autoRechargePrice && (
                        <div>
                            <p className="specialoffer-title">
                                Get {autoRechargePrice} off your next {num} {specialOffer.para}
                            </p>
                            <p className="specialoffer-description">
                                {specialOffer.discountDetails} {endDate}
                            </p>
                        </div>
                    )}
                    {autoRechargeBonusData && (
                        <div>
                            <p className="specialoffer-title">
                                Get {autoRechargeBonusData} bonus data on your next {num} {specialOffer.para}
                            </p>
                            <p className="specialoffer-description">
                                {specialOffer.bonusDetails} {endDate}
                            </p>
                        </div>
                    )}
                </div>
            )}

            <Checkbox
                className="my-4 able-boost-checkbox"
                label={checkbox.label}
                checked={isAutoRechargeChecked}
                name="auto-recharge-checkbox"
                value="autoRecharge"
                variant="Comfortable"
                description={autoRechargeDescription}
                Picto={<img src={autorechargePicto} alt="Auto recharge icon" />}
                onChange={toggleAutoRecharge}
            />

            {isAutoRechargeChecked && (
                <Fragment>
                    <TextStyle className="mb-1" alias="FinePrint">
                        Our prepaid plan prices are subject to change. This means your auto recharge amount may change from time to time in line with your service cost.{' '}
                    </TextStyle>
                    <TextStyle className="mb-4" alias="FinePrint">
                        <a href="https://boost.com.au/pages/auto-recharge-terms-and-conditions?_pos=3&_sid=fa0cec6f0&_ss=r" target={'_blank'}>
                            {priceRise ? `View current and new (effective 22 October 2024) auto recharge terms and conditions` : `View auto recharge terms and conditions`}
                        </a>
                    </TextStyle>
                    {priceRise && (
                        <MessageSection
                            developmentUrl={ableSpriteSheet}
                            variant="Information"
                            description={checkbox.alert.description}
                            titleText={checkbox.alert.titleText}
                            actionCallback={newTabForRechargeInfo}
                            actionElement="button"
                            actionText={checkbox.alert.actionText}
                        />
                    )}
                </Fragment>
            )}

            <ModalContent
                className='confirm-ar-modal'
                title={priceComparisonTableConfig.title}
                bodyContent={
                    <>
                        <TextStyle element="h3" className="mb-2 mt-3" alias="HeadingC">
                            {planTableData.label}
                        </TextStyle>
                        <Table columns={priceComparisonTableConfig.columns} data={planTableData.data} />
                    </>
                }
                footerContent={
                    <div className="d-flex justify-content-end">
                        <ActionButton
                            className="boost-able-button mt-4"
                            element="button"
                            variant="HighEmphasis"
                            label="Okay"
                            onClick={togglePlanModal} />
                    </div>
                }
                variant='Comfortable'
                isShowing={isPlanModalVisible}
                developmentUrl={ableSpriteSheet}
                setHideDialog={togglePlanModal}
            />
        </div>
    );
};

export default AutoRecharge;
