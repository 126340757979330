import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return {
        appData: state.app.appData,
        loading: state.offerDetails.loading,
        errors: state.offerDetails.errors,
        customerOffers: state.offerDetails.customerOffers,
        showSkipRechargeModal: state.offerDetails.showSkipRechargeModal,
        yourContactDetails: state.yourContactDetails,
        deviceData: state.welcome.deviceData,
        surchargeTable: state.offerDetails.surchargeTable,
        surchargeTableLoaded: state.offerDetails.surchargeTableLoaded,
        surchargeTableError: state.offerDetails.surchargeTableError
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators);
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const offerDetails = connect(mapStateToProps, mapDispatchToProps)(View);

export default offerDetails;
