import { TextStyle, Surface, Spacing } from '@able/react';
import { getSepratedSurchargeData } from '../../adapters/utils';

export default function AmountBreakdown({
    isSurchargeAPIDown,
    surchargeData = [],
    selectedOffer = {},
    isDebitCard,
    autoRecharge,
    starterCredit = 0
}) {
    const { dueToday, basePrice, arDiscountedPrice } = getSepratedSurchargeData(surchargeData);
    const hasFirstTimeDiscount = Boolean(selectedOffer.firstTimeDiscountPrice);
    const hasARDiscount = Boolean(arDiscountedPrice);

    return (
        <Surface background="materialBaseSecondary" cornerRadius="cornerRadiusSoft" variant="SurfaceFlat" className="surchargeInfoContainer mt-4">
            <Spacing bottom="spacing3x" left="spacing3x" right="spacing3x" top="spacing3x">
                {
                    starterCredit === 0 ? (
                        <>
                            <div className="surchargeContent">
                                <TextStyle alias="HeadingC" element="span">Due today</TextStyle>
                                <TextStyle alias="HeadingC" element="span">${dueToday?.revisedAmount}</TextStyle>
                            </div>
                            <div className="dueTodayDescription">
                                <TextStyle alias="FinePrint" colour="Subtle" className="mt-1">
                                    Your recharge amount is ${dueToday?.originalAmount}{hasFirstTimeDiscount && ` with offer applied`}. {!isDebitCard && !isSurchargeAPIDown && `This includes $${dueToday?.surchargeAmount} credit card processing fee.`}
                                    {isSurchargeAPIDown && !isDebitCard && ` We’re unable to include the credit card processing fee this time, but your next auto recharge will include it.`}
                                    {isSurchargeAPIDown && !isDebitCard && ` You can view this amount by signing into My Boost, or in the SMS or email reminder.`}
                                </TextStyle>
                            </div>
                        </>
                    ) : ''
                }
                {autoRecharge && (
                    (starterCredit === 0 && (hasARDiscount || hasFirstTimeDiscount))
                    || starterCredit !== 0) && (
                        <div className="ongoingRechargeContent">

                            <div className="surchargeContent">
                                <TextStyle alias="HeadingC" element="span">Ongoing recharges</TextStyle>
                                {!hasARDiscount && <TextStyle alias="HeadingC" element="span">${basePrice?.revisedAmount}</TextStyle>}
                            </div>

                            {hasARDiscount && (
                                <>
                                    <div className="surchargeContent">
                                        <TextStyle alias="TextBodyShort" element="span">Next {selectedOffer.num} recharges</TextStyle>
                                        <TextStyle alias="HeadingD" element="span">${arDiscountedPrice?.revisedAmount}</TextStyle>
                                    </div>
                                    <div className="">
                                        <TextStyle alias="FinePrint" colour="Subtle" className="mt-1">Your recharge amount is ${arDiscountedPrice?.originalAmount} with offer applied. {!isDebitCard && !isSurchargeAPIDown && `This includes $${arDiscountedPrice.surchargeAmount} credit card processing fee.`} {isSurchargeAPIDown && !isDebitCard && ` We’re unable to include the credit card processing fee this time. You can view this amount by signing into My Boost, or in the SMS or email reminder.`}</TextStyle>
                                    </div>
                                    <div className="surchargeContent">
                                        <TextStyle alias="TextBodyShort" element="span">After that</TextStyle>
                                        <TextStyle alias="HeadingD" element="span">${basePrice?.revisedAmount}</TextStyle>
                                    </div>
                                </>
                            )}

                            <div className="">
                                <TextStyle alias="FinePrint" colour="Subtle" className="mt-1">Your recharge amount is ${basePrice.originalAmount}. {!isDebitCard && !isSurchargeAPIDown && `This includes $${basePrice.surchargeAmount} credit card processing fee.`} {isSurchargeAPIDown && !isDebitCard && ` We’re unable to include the credit card processing fee this time. You can view this amount by signing into My Boost, or in the SMS or email reminder.`}</TextStyle>
                            </div>

                        </div>)}
            </Spacing>
        </Surface>
    );
}
