import React from 'react';
import AUTORECHARGE_LOCALE from '../../../assets/locale/autorecharge-locale';
import { TextStyle } from '@able/react';
import './autorechargeenabled.css';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import defaultImgSrc from '../../../assets/img/AR_Sml-red.svg';
import { getAREnabledAmountDescription } from '../../../adapters/utils';

const AutoRechargeEnabledCard = (props) => {
    const { loggedIn = false, hasTdiLogin = false, newOfferSelected: { usageQuota = '', autoRechargeBonusData = '', autoRechargePercentage = '', autoRechargePrice = '', num } = {}, actualAmount = 0, starterCredit = 0, surcharge = {}, isSurchargeAPIDown, isDebitCard } = props;
    const {
        AutoRechargeEnabledCard: { autoRechargeContent = '', authPara1 = '', unauthpara1 = '', title = '' }
    } = AUTORECHARGE_LOCALE;
    const price = actualAmount || starterCredit;
    if (!hasTdiLogin) {
        addDataLayerEventInfo('impression', 'auto recharge module', 'success', 'Auto recharge enabled - New TDI');
    } else {
        addDataLayerEventInfo('impression', 'auto recharge module', 'success', 'Auto recharge enabled - Existing TDI');
    }
    return (
        <div className="auto-recharge-details">
            <img className="cal-image" alt="" src={defaultImgSrc} />
            <TextStyle className="pt-24 mtxl" element={'h3'} alias="HeadingC">
                {title}
            </TextStyle>
            <hr />
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {getAREnabledAmountDescription({ para: autoRechargeContent, isDebitCard, plan: props.newOfferSelected, isSurchargeAPIDown, surcharge })}
            </TextStyle>
            <TextStyle>
                {/* eslint-disable-next-line no-nested-ternary */}
                {autoRechargeBonusData ?
                    `You'll receive ${autoRechargeBonusData} bonus data on your next ${num} recharges as part of your auto recharge special offer.` :
                    autoRechargePrice ?
                        `You'll receive ${autoRechargePrice} off your next ${num} recharges.` : autoRechargePercentage ?
                            `You'll receive ${autoRechargePercentage} off your next ${num} recharges.` :
                            null}
            </TextStyle>
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {loggedIn || hasTdiLogin ? authPara1 : unauthpara1}
            </TextStyle>
        </div>
    );
};

export default AutoRechargeEnabledCard;
