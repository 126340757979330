import React, { useRef, useEffect, Fragment, useState } from 'react';
import { focusOnModal, getAbleSpriteSheet } from '../../adapters/utils';
import { ConfirmationDialog } from '@able/react';

const ableSpriteSheet = getAbleSpriteSheet();

const SurchargeAPIDownModal = ({toggleModal, isModalOpen}) => {
    const surchargeDownModalRef = useRef(null);

    useEffect(() => {
        surchargeDownModalRef && surchargeDownModalRef.current && focusOnModal(surchargeDownModalRef, 0);
    }, [isModalOpen]);

    return(
        <Fragment>
            <ConfirmationDialog title="Credit card processing fee not included"
                description="We're unable to add the additional fee this time, but your next recharge may include it. If it does, we’ll let you know in the total to pay."
                isShowing={isModalOpen}
                className="surcharge-rate-confirm-dialog"
                stackButtonOnVXS={false}
                developmentUrl={ableSpriteSheet}
                confirmButtonLabel='Continue'
                setHideDialog={toggleModal}
                focusOnCloseRef={surchargeDownModalRef}
            >
            </ConfirmationDialog>
        </Fragment>
    );
}

export default SurchargeAPIDownModal;