import './billpay.css';

import React, { Component } from 'react';
import axios from 'axios';
import { MessageSection, TextStyle, MessageInline } from '@able/react';
import { Loader } from '../../../components';
import { PAYMENT_ERROR } from '../../../adapters/errorCode';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import lock from '../../../assets/img/svg/secure-payment.svg';
import RadioBar from '../../../components/RadioBar';
import SpinningButton from '../../../components/SpinningButton/SpinningButton';
import AutoRecharge from '../../../routes/payment/components/auto-recharge';
import { addCartLayer, addDataLayerCartPrice, addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import ConnectedLoader from '../../../components/Loader/loadingSpinner';
import AutoRechargeConfirmationModal from '../AutoRechargeConfirmationModal';
import FieldError from '../../../common/components/fieldError/fieldError';
import { getAbleSpriteSheet } from '../../../adapters/utils';
import { focusOnElement } from '../../../adapters/utils';
import AmountBreakdown from '../AmountBreakdown';
import SurchargeAPIDownModal from '../SurchargeAPIDownModal';
import SubmittedCardEdit from '../SubmittedCardEdit';
import { CreditCardSvg } from '../../CreditCardSvg';

const ableSpriteSheet = getAbleSpriteSheet();
let surchargeCallSource;
const ccLogoMap = {
    MASTER_CARD: {
        title: 'MasterCard',
        logo: 'masterCard'
    },
    VISA: {
        title: 'Visa',
        logo: 'visa'
    }
};

class Billpay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postActionURL: null,
            billPayTokenId: '',
            isLoaded: false,
            showCreditForm: false,
            shouldSaveCreditCard: false,
            eligibleForAutorecharge: false,
            ccBrantreeValidationInProgress: false,
            isARConfirmationModalOpen: false,
            priceRise: false,
            paymentDetailsConfirmationCard: {},
            isSuchargeAPIDownModalOpen: false
        };
        this.submitPaymentDetails = this.submitPaymentDetails.bind(this);
        this.showCreditFormMethod = this.showCreditFormMethod.bind(this);
        this.getPaymentRecharge = this.getPaymentRecharge.bind(this);
        this.getCardType = this.getCardType.bind(this);
        this.submitAutoRechargeWithNewCreditCard = this.submitAutoRechargeWithNewCreditCard.bind(this);
        this.submitAutoRechargeWithSavedCard = this.submitAutoRechargeWithSavedCard.bind(this);
        this.closeARConfirmationModal = this.closeARConfirmationModal.bind(this);
    }
    componentWillMount() {
        this.setState({ isLoaded: false });
        const { getSavedCardList, getBraintreePaymentInitialised } = this.props.actions;

        const { payment: { braintree: { token } = {} } = {} } = this.props.state;
        const {
            uiState: { loggedIn = false }
        } = this.props.state.app.appData;

        const payload = {
            token
        };
        const { loggedInCustomerData: { anonymous = true } = {}, appData: { customer: { data: { stepUpSuccessful = false } = {} } = {} } = {} } = this.props.state.app;
        addCartLayer();
        addDataLayerCartPrice('paymentType', 'New Card');
        addDataLayerEventInfo('cardPaymentMethod');
        if (loggedIn && ((anonymous && stepUpSuccessful) || !anonymous)) {
            getSavedCardList();
            this.setState({
                isLoaded: true
            });
        } else {
            getBraintreePaymentInitialised(payload, 'new');
            if (token) {
                this.setState({
                    isLoaded: true
                });
            }
        }

        const { deviceData: { simVersion = '' } } = this.props.state.welcome;
        const { setAutoRecharge } = this.props.actions;
        if (simVersion === 'PRE_PRICE_RISE') {
            this.setState({ priceRise: true });
            setAutoRecharge(false);
        }
    }

    componentDidUpdate(prevProps) {
        const { validatedBraintreeData, errors } = this.props.state.payment;
        const { errors: prevErrors = {} } = prevProps.state.payment;
        if (errors &&
            prevErrors.failedAttempts !== errors.failedAttempts) {
            let immediateFocusOnError = true;
            if (this.state.isARConfirmationModalOpen) {
                this.closeARConfirmationModal();
                immediateFocusOnError = false;
            }
            if (errors.errorCode === PAYMENT_ERROR) {
                focusOnElement('#server-error-description', immediateFocusOnError ? 0 : 1000, immediateFocusOnError);
            }
        }

        if(validatedBraintreeData !== prevProps.state.payment.validatedBraintreeData){
            this.setState({ paymentDetailsConfirmationCard : validatedBraintreeData});
        }

        const {
            appData: {
                uiState: { loggedIn = false },
                customer: { data: { stepUpSuccessful = false } = {} } = {}
            },
            loggedInCustomerData: { anonymous = true } = {}
        } = this.props.state.app;
        const eligibleForAutorecharge = (loggedIn && !anonymous) || stepUpSuccessful;
        const { payment: { autoRechargeCardData: { brainTreeNonceData } = {} } = {} } = this.props.state;
        const { payment: { autoRechargeCardData: { brainTreeNonceData: prevBrainTreeNonceData } = {} } = {} } = prevProps.state;
        if (eligibleForAutorecharge && (brainTreeNonceData && !prevBrainTreeNonceData)) {
            this.showConfirmationSheet();
        }
    }

    componentWillUnmount() {
        const { resetHostedFields } = this.props.actions;
        const {
            loading: { activationInProgress }
        } = this.props.state.app.appData;
        resetHostedFields();
        if (!activationInProgress) {
            this.resetSurchargeSection();
        }
    }

    render() {
        const { isLoaded, ccBrantreeValidationInProgress, priceRise } = this.state;
        const {
            uiState: { loggedIn = false, isEsimActivation = false },
            loading: { activationInProgress }
        } = this.props.state.app.appData;
        const { paymentSelectedId } = this.props;

        const {
            savedCardList: { savedCards: { data: cardList = [] } = {} } = {},
            loading: { isPaymentInProgress, isSurchargeInProgress },
            hostedFields: { ccInitialised = false }
        } = this.props.state.payment;
        const { payment: { braintree: { token } = {}, autoRecharge = true, surcharge = [], isSurchargeProcessed, isDebitCardFromSurcharge, isDebitCardFromBRNTreeORSavedCard, isSurchargeAPIDown } } = this.props.state;
        const { getBraintreePaymentInitialised, resetHostedFields, setAutoRecharge } = this.props.actions;
        const { loggedInCustomerData: { anonymous = true } = {}, appData: { customer: { data: { stepUpSuccessful = false } = {} } = {} } = {} } = this.props.state.app;
        const { starterCredit } = this.props.state.app.appData.serviceNumber;
        const { amount = 0 } = this.props;
        const { deviceData: { simVersion = '' } } = this.props.state.welcome;
        const payload = {
            token
        };
        let paymentSelectedIdValue;
        paymentSelectedIdValue = paymentSelectedId;

        const newOfferSelected = this.props.state.offerDetails.selectedOffer;

        if (!loggedIn || cardList.length === 0) {
            paymentSelectedIdValue = 'new';
        }
        if (!isLoaded) {
            return (
                <div className="billpay-loader">
                    <Loader />
                </div>
            );
        }

        // reset hosted field initialised value. Have to do it here since the service for braintree gets invoked multiple times for saved card scenario.
        if (ccInitialised && (paymentSelectedIdValue !== 'new' || !loggedIn)) {
            resetHostedFields();
        }

        if (cardList.length !== 0 && paymentSelectedIdValue === 'new') {
            getBraintreePaymentInitialised(payload, 'new');
        } else if (cardList.length !== 0) {
            getBraintreePaymentInitialised(payload, 'saved');
        }
        const isDebitCard = isDebitCardFromSurcharge || isDebitCardFromBRNTreeORSavedCard;

        const eligibleForAutorecharge = ((loggedIn && !anonymous) || stepUpSuccessful);
        const isSubmitInProgress = this.state.isARConfirmationModalOpen || ccBrantreeValidationInProgress || isPaymentInProgress || activationInProgress || isSurchargeInProgress;
        const autoRechargeProps = {
            autoRecharge,
            actualAmount: amount,
            plan: newOfferSelected,
            disableARCheckbox: isSubmitInProgress,
            simVersion,
            actions: {
                setAutoRecharge
            },
            surcharge,
            isDebitCard,
            isSurchargeAPIDown
        };

        return (
            <div>
                {!priceRise && <AutoRechargeConfirmationModal
                    isModalOpen={this.state.isARConfirmationModalOpen}
                    closeCallback={this.closeARConfirmationModal}
                    {...this.props}
                    activationInProgress={activationInProgress}
                    actualAmount={amount}
                    plan={newOfferSelected}
                    surcharge={surcharge}
                    isDebitCard={isDebitCard}
                    isSurchargeAPIDown={isSurchargeAPIDown}
                />}

                {isSurchargeAPIDown && !isDebitCard && <SurchargeAPIDownModal toggleModal={this.toggleSurchargeAPIDownModal} isModalOpen={this.state.isSuchargeAPIDownModalOpen} />}

                {paymentSelectedIdValue === 'new' && !ccInitialised && <ConnectedLoader />}
                <form className="credit-card-form" noValidate>
                    {this.renderErrors()}
                    <div className="mb4xl">
                        <MessageInline
                            developmentUrl={ableSpriteSheet}
                            variant="Information"
                            description={<> No fees apply for debit card payments. Credit card and PayPal payments will incur <a className="custom-link" role="button" tabIndex="0" onClick={this.props.toggleModal}> additional fees </a>.</>}
                        />
                    </div>
                    {loggedIn &&
                        cardList.length !== 0 &&
                        !isSurchargeProcessed &&
                        cardList.map((card) => (
                            <div className="saved-card-details">
                                <Field
                                    type="radio"
                                    name="savedCardRadio"
                                    id="savedCardId"
                                    isActive={paymentSelectedIdValue === card.id}
                                    value={card.id}
                                    onChange={() => {
                                        this.resetSurchargeSection();
                                    }}
                                    component={RadioBar}
                                    label={
                                        <div>
                                            <TextStyle alias="HeadingD" className="mb-2">{this.getCardType(card)}</TextStyle>
                                            <TextStyle alias="TextBodyShort">
                                                {card.debit?.toLowerCase() === 'yes' ? 'Debit card' : card.debit?.toLowerCase() === 'no' ? 'Credit card' : ''} ending in {card.last4}
                                            </TextStyle>
                                            <TextStyle alias="FinePrint" colour="Subtle" className="mt-1">Expiry {card.expiryMonth}/{card.expiryYear}</TextStyle>
                                        </div>
                                    }
                                />
                            </div>
                        ))}
                    {loggedIn && cardList.length !== 0 && !isSurchargeProcessed && (
                        <Field
                            type="radio"
                            name="savedCardRadio"
                            className="saved-card-btn"
                            value="new"
                            isActive={paymentSelectedIdValue === 'new'}
                            component={RadioBar}
                            onChange={this.showCreditFormMethod}
                            label={
                                <div>
                                    <TextStyle alias="TextBodyShort">Use another card </TextStyle>
                                </div>
                            }
                        />
                    )}
                    {(!loggedIn || paymentSelectedIdValue === 'new') && !isSurchargeProcessed && (
                        <div className="CreditCardPayment-braintree">
                            <div className="CreditCardPayment-expiry-cardinfo">
                                <label id="lblCCNumber" htmlFor="cc-number">
                                    <TextStyle alias="Label">Card number</TextStyle>
                                </label>
                                <div className="CreditCardPayment-hosted-field" id="cc-number" />
                                <div className="CreditCardPayment-invalid-message">
                                    <FieldError textAlias="FinePrint" iconSize="24" id="cc-error">
                                        Required field. Please enter a valid 16 digit card number.
                                    </FieldError>
                                </div>
                                <div id="CreditCardPayment-card-image-mc" className="master-card">
                                    <CreditCardSvg type={ccLogoMap.MASTER_CARD.logo} title={ccLogoMap.MASTER_CARD.title}/>
                                </div>
                                <div id="CreditCardPayment-card-image-visa" className="visa">
                                    <CreditCardSvg type={ccLogoMap.VISA.logo} title={ccLogoMap.VISA.title}/>
                                </div>
                            </div>
                            <div className="CreditCardPayment-expiry">
                                <div className="CreditCard-expiry-box pt-5">
                                    <label id="lblMonth" htmlFor="cc-exp-month">
                                        <TextStyle alias="Label">Expiry date</TextStyle>
                                    </label>
                                    <div className="CreditCardPayment-expiry-month">
                                        <div className="CreditCardPayment-hosted-field customselect" id="cc-exp-month" />
                                        <div className="CreditCardPayment-invalid-message">
                                            <FieldError textAlias="FinePrint" iconSize="24" id="error-month">
                                                Please select a valid month
                                            </FieldError>
                                        </div>
                                    </div>
                                    <div className="CreditCardPayment-expiry-year">
                                        <div className="CreditCardPayment-hosted-field customselect" id="cc-exp-year" />
                                        <div className="CreditCardPayment-invalid-message">
                                            <FieldError textAlias="FinePrint" iconSize="24" id="error-year">
                                                Please select a valid year
                                            </FieldError>
                                        </div>
                                    </div>
                                    <div className="CreditCardPayment-expiry-month-year">
                                        <div className="CreditCardPayment-invalid-message" id="error-month-year">
                                            <FieldError textAlias="FinePrint" iconSize="24" className="expiry-month-year">
                                                Invalid input. Please select a valid expiry date.
                                            </FieldError>
                                        </div>
                                    </div>
                                </div>

                                <label className="w-100" id="lblCCCvv" htmlFor="cc-cvv">
                                    <TextStyle className="pt-5" alias="Label">
                                        Security code (CVV)
                                    </TextStyle>
                                </label>
                                <div className="CreditCardPayment-hosted-field CreditCardPayment-cvv" id="cc-cvv" />
                                <FieldError textAlias="FinePrint" iconSize="24" id="cvv-error">
                                    This is the 3 or 4-digit number on your card.
                                </FieldError>
                            </div>
                        </div>
                    )}
                    {isSurchargeProcessed && (
                        <SubmittedCardEdit 
                            paymentDetailsConfirmationCard={this.state.paymentDetailsConfirmationCard}
                            isDebitCard={isDebitCard}
                            resetSurchargeSection={this.resetSurchargeSection}
                            ccLogoMap={ccLogoMap}/>
                    )}
                    {eligibleForAutorecharge && starterCredit === 0 && !priceRise && isSurchargeProcessed && <AutoRecharge {...autoRechargeProps} />}
                </form>
                {isSurchargeProcessed && (
                    <AmountBreakdown
                        starterCredit={starterCredit}
                        autoRecharge={autoRecharge}
                        isDebitCard={isDebitCard}
                        selectedOffer={newOfferSelected}
                        surchargeData={surcharge} 
                        isSurchargeAPIDown={isSurchargeAPIDown}/>
                )}
                {(cardList.length !== 0 || !loggedIn || paymentSelectedIdValue === 'new') && (
                    <React.Fragment>
                        {starterCredit !== 0 && ( // value sim
                            ((!autoRecharge || isSurchargeProcessed) ?
                                <SpinningButton
                                    variant="HighEmphasis"
                                    stylingClass="CreditCardPayment-submit-button"
                                    isDisabled={isSubmitInProgress}
                                    isLoading={isSubmitInProgress}
                                    onSubmit={() => {
                                        if (!paymentSelectedIdValue) {
                                            // TODO: Please select an autorecharge validation
                                            return;
                                        }

                                        if (eligibleForAutorecharge && autoRecharge) {
                                            if (paymentSelectedIdValue === 'new') {
                                                // payment screen validation and then bring up the confirmation sheet
                                                this.submitAutoRechargeWithNewCreditCard();
                                            } else {
                                                // saved card details and then bring up the confirmation sheet
                                                this.submitAutoRechargeWithSavedCard(paymentSelectedIdValue);
                                            }
                                        }
                                        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'Activate SIM');
                                    }}
                                    aria-describedby="server-error-description"
                                    buttonText="Activate SIM"
                                /> :
                                <SpinningButton
                                    isDisabled={isSubmitInProgress}
                                    isLoading={isSubmitInProgress}
                                    onSubmit={() => { this.showSurchargeRates(paymentSelectedIdValue);
                                        this.toggleSurchargeAPIDownModal();
                                        this.setIsDebitCard(paymentSelectedIdValue); }}
                                    stylingClass="auto-recharge-setup-button boost-button--medium-emphasis"
                                    variant="MediumEmphasis"
                                    buttonText="Confirm payment details" />
                            )
                        )}
                        {starterCredit === 0 && ( // non-value sim/$2 sim
                            isSurchargeProcessed ?
                                <div tabIndex="0" id="btnPayWithBraintree">
                                    <SpinningButton
                                        variant="HighEmphasis"
                                        // id="btnPayWithBraintree"
                                        stylingClass="CreditCardPayment-submit-button"
                                        isDisabled={isSubmitInProgress}
                                        isLoading={isSubmitInProgress}
                                        onSubmit={() => {
                                            if (!paymentSelectedIdValue) {
                                                // Please select an autorecharge option
                                                return;
                                            }
                                            if (eligibleForAutorecharge && autoRecharge) {
                                                if (paymentSelectedIdValue === 'new') {
                                                    // payment screen validation and then bring up the confirmation sheet
                                                    this.submitAutoRechargeWithNewCreditCard();
                                                } else {
                                                    // saved card details and then bring up the confirmation sheet
                                                    this.submitAutoRechargeWithSavedCard(paymentSelectedIdValue);
                                                }
                                            }
                                            // if auto recharge is not selected then existing flow should continue
                                            if (!eligibleForAutorecharge || !autoRecharge) {
                                                if (paymentSelectedIdValue !== 'new') {
                                                    this.savedCardPayment(paymentSelectedIdValue);
                                                } else {
                                                    this.submitPaymentDetails();
                                                }
                                            }
                                            addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'Activate SIM');
                                        }}
                                        aria-describedby="server-error-description"
                                        buttonText={isEsimActivation ? 'Pay & continue' : 'Activate SIM'}
                                    />
                                </div>
                                :
                                <SpinningButton
                                    stylingClass="CreditCardPayment-submit-button boost-button--medium-emphasis"
                                    variant="MediumEmphasis"
                                    isDisabled={isSubmitInProgress}
                                    isLoading={isSubmitInProgress}
                                    onSubmit={() => { this.showSurchargeRates(paymentSelectedIdValue);
                                        this.toggleSurchargeAPIDownModal();
                                        this.setIsDebitCard(paymentSelectedIdValue); }}
                                    buttonText={'Confirm payment details'}
                                />
                        )}
                        {isSurchargeProcessed && (
                            <TextStyle className="mt-3 payment-lock" alias="FinePrint">
                                <img className="custom-lock" src={lock} alt="Your data is protected with TLS technology" />
                                The information you provide us will be treated in accordance with our
                                <a href="https://www.telstra.com.au/privacy/privacy-statement" className="boost-privacy-statement" rel="noopener noreferrer" target="_blank">
                                    {'  '}
                                    Privacy Statement
                                </a>
                            </TextStyle>)}

                        <hr className="credit-card-submit-margin mb-5" />
                    </React.Fragment>
                )}
            </div>
        );
    }

    setIsDebitCard = (paymentSelectedIdValue) => {
        const { savedCardList: { savedCards: { data: cardList = [] } = {} } = {} } = this.props.state.payment;
        const { setIsSelectedDebitCard } = this.props.actions;
        if(paymentSelectedIdValue !== 'new'){
            let selectedCard = cardList.find(card => card.id === paymentSelectedIdValue);
            if(selectedCard){
                setIsSelectedDebitCard(selectedCard?.debit.toLowerCase());
            }
        }
    }

    toggleSurchargeAPIDownModal = () => {
        this.setState({ isSuchargeAPIDownModalOpen: !this.state.isSuchargeAPIDownModalOpen });
    }

    renderErrors() {
        const { errors } = this.props.state.payment;
        let errorText = '';
        let errorHeading = '';
        if (errors && (errors.hasError || errors.errorCode === PAYMENT_ERROR)) {
            errorHeading = errors.errorHeading;
            errorText = errors.errorBody;
        }
        if (errorHeading !== '') {
            return (
                <div id="server-error-description" tabIndex="-1" className="Billpay-error">
                    <MessageSection
                        variant="Error"
                        developmentUrl={ableSpriteSheet}
                        description={errorText}
                        titleText={errorHeading} />
                </div>
            );
        }
        return null;
    }

    showSurchargeRates(id) {
        const { ccBraintreeValidation } = this.props.actions;
        const { amount = 0, paymentSelectedId, getSurchargeRates } = this.props;
        const payload = {
            paymentAmount: amount,
            paymentType: paymentSelectedId === 'new' ? 'NONCE' : 'tid',
            paymentNonce: '',
            id
        };
        const CancelToken = axios.CancelToken;
        surchargeCallSource = CancelToken.source();
        if (paymentSelectedId === 'new') {
            this.setState({ ccBrantreeValidationInProgress: true });
            ccBraintreeValidation(payload, (result) => {
                if (result.valid) {
                    getSurchargeRates({
                        paymentAmount: payload.paymentAmount,
                        paymentType: payload.paymentType,
                        paymentNonce: payload.paymentNonce,
                        id: payload.id
                    }, surchargeCallSource.token);
                }
                this.setState({ ccBrantreeValidationInProgress: false });
            });
        } else {
            getSurchargeRates(payload, surchargeCallSource.token);
            const { savedCardList: { savedCards: { data: cardList = [] } = {} } = {} } = this.props.state.payment;
            let selectedCard = cardList.find(card => card.id === paymentSelectedId);
            this.setState({ paymentDetailsConfirmationCard : selectedCard });
        }
    }

    savedCardPayment(id) {
        const { app: { appData: { customerInfo = {} } } = {} } = this.props.state;
        const { customerAccountUUID = '' } = customerInfo;
        const { shoppingCart: { shoppingCartId = '' } = {} } = this.props.state.app.appData;
        const { payAndActivateWithSavedCard } = this.props.actions;
        const { amount = 0 } = this.props;
        const payload = {
            customerUUID: customerAccountUUID,
            identityProfileId: 'required',
            paymentAmount: amount,
            paymentType: 'tid',
            paymentNonce: '',
            shoppingCartId,
            deviceData: '',
            registerInVault: false,
            id,
            serviceId: this.props.serviceId
        };
        payAndActivateWithSavedCard(payload);
    }

    submitAutoRechargeWithSavedCard(id) {
        const { app: { appData: { customerInfo = {} } } = {} } = this.props.state;
        const { customerAccountUUID = '' } = customerInfo;
        const { shoppingCart: { shoppingCartId = '' } = {} } = this.props.state.app.appData;
        const { setSavedCardAutoRecharge } = this.props.actions;
        const { amount = 0 } = this.props;

        const { payment: { autoRecharge = true } = {} } = this.props.state;
        let registerInVaultValue = false;
        if (autoRecharge) {
            registerInVaultValue = autoRecharge;
        }

        const payload = {
            customerUUID: customerAccountUUID,
            identityProfileId: 'required',
            paymentAmount: amount,
            paymentType: 'tid',
            paymentNonce: '',
            shoppingCartId,
            paymentMethodType: 'CREDIT_CARD',
            deviceData: '',
            registerInVault: registerInVaultValue,
            id,
            serviceId: this.props.serviceId
        };
        setSavedCardAutoRecharge(payload);
        this.showConfirmationSheet();
    }

    submitPaymentDetails() {
        const {
            uiState: { loggedIn = false }
        } = this.props.state.app.appData;
        const { payAndActivateWithNewCard } = this.props.actions;
        const { loggedInCustomerData: { anonymous = true } = {}, appData: { customer: { data: { stepUpSuccessful = false } = {} } = {}, autoRecharge = false } = {} } = this.props.state.app;

        const offerSaveCC = (loggedIn && !anonymous) || stepUpSuccessful;
        let registerInVaultValue = false;
        if (offerSaveCC) {
            registerInVaultValue = autoRecharge;
        }

        const { app: { appData: { customerInfo = {} } } = {} } = this.props.state;
        const { customerAccountUUID = '2fa85f64-5717-4562-b3fc-2c963f66afa8', identityProfileId = 'required' } = customerInfo;

        const { shoppingCart: { shoppingCartId = '' } = {} } = this.props.state.app.appData;
        const { amount = 0 } = this.props;
        const { payment: { validatedBraintreeData = {} } = {} } = this.props.state;
        const payload = {
            customerUUID: customerAccountUUID,
            paymentAmount: amount,
            paymentType: 'NONCE',
            paymentNonce: validatedBraintreeData.paymentNonce || '',
            shoppingCartId,
            deviceData: validatedBraintreeData.deviceData || '',
            registerInVault: registerInVaultValue,
            serviceId: this.props.serviceId
        };
        if (offerSaveCC) {
            payload.identityProfileId = identityProfileId;
        }
        if (registerInVaultValue) {
            addDataLayerEventInfo('saveCardDetails');
        }
        payAndActivateWithNewCard(payload);
    }

    submitAutoRechargeWithNewCreditCard() {
        const { payAndActivateAutoRechargeWithNewCard } = this.props.actions;
        const { payment: { autoRecharge = true } = {} } = this.props.state;
        const { payment: { validatedBraintreeData = {} } = {} } = this.props.state;
        let registerInVaultValue = false;
        if (autoRecharge) {
            registerInVaultValue = autoRecharge;
        }

        const { app: { appData: { customerInfo = {} } } = {} } = this.props.state;
        const { customerAccountUUID = '2fa85f64-5717-4562-b3fc-2c963f66afa8' } = customerInfo;
        const { shoppingCart: { shoppingCartId = '' } = {} } = this.props.state.app.appData;
        const amount = 0;
        const payload = {
            customerUUID: customerAccountUUID,
            paymentAmount: amount,
            paymentType: 'NONCE',
            paymentNonce: validatedBraintreeData.paymentNonce || '',
            shoppingCartId,
            deviceData: validatedBraintreeData.deviceData || '',
            registerInVault: registerInVaultValue,
            serviceId: this.props.serviceId,
            paymentMethodType: 'CREDIT_CARD'
        };
        if (registerInVaultValue) {
            payload.identityProfileId = 'required';
        }
        payAndActivateAutoRechargeWithNewCard(payload);
    }

    closeARConfirmationModal() {
        this.setState({ isARConfirmationModalOpen: false });
        focusOnElement('#btnPayWithBraintree', 0, true);
    }

    showCreditFormMethod() {
        this.resetSurchargeSection();
        this.setState({
            showCreditForm: !this.state.showCreditForm
        });
    }

    resetSurchargeSection = () => {
        const { payment: { braintree: { token } = {}, loading: { isSurchargeInProgress } } = {} } = this.props.state;
        if (isSurchargeInProgress) {
            surchargeCallSource.cancel('cancel');
        } else {
            const { resetSurchargeRates, paymentSelectedId } = this.props;
            resetSurchargeRates();
            const {
                uiState: { loggedIn = false }
            } = this.props.state.app.appData;
            const { getBraintreePaymentInitialised } = this.props.actions;
            const payload = {
                token
            };
        
            if(!loggedIn){
                getBraintreePaymentInitialised(payload, "new");
            }else{
                if (paymentSelectedId === 'new') {
                    getBraintreePaymentInitialised(payload, 'new');
                } else {
                    getBraintreePaymentInitialised(payload, 'saved');
                }
            }
        }
    }

    getCardType(card) {
        let cardType = card.cardType;
        switch (card.cardType.toLowerCase()) {
            case 'master_card':
                cardType = 'MasterCard';
                break;
            case 'visa':
                cardType = 'Visa';
                break;
            default:
                cardType = 'American Express';
        }

        return cardType;
    }

    getPaymentRecharge(event) {
        if (event.tid) {
            this.savedCardPayment(event);
        } else {
            this.submitPaymentDetails(event);
        }
    }

    showConfirmationSheet() {
        this.setState({ isARConfirmationModalOpen: true });
        addDataLayerEventInfo('impression', 'auto recharge module', '', 'Confirm auto recharge');
    }
}

const PaymentForm = reduxForm({ form: 'paymentCCForm', destroyOnUnmount: false, touchOnBlur: false })(Billpay);
const ConnectedFormAndState = connect((state) => {
    const { savedCardList: { savedCards: { data: cardList = [] } = {} } = {} } = state.payment;

    const selector = formValueSelector('paymentCCForm');
    let paymentSelectedId = selector(state, 'savedCardRadio');

    if (!paymentSelectedId) {
        paymentSelectedId = cardList.length ? cardList[0].id : 'new';
    }

    return { initialValues: {}, paymentSelectedId };
})(PaymentForm);

export default ConnectedFormAndState;

// export default Billpay;
