import React from 'react';
import { InlineLink, TextStyle } from '@able/react';
import tick from '../../../assets/img/svg/controls-ui-tick.svg';
import { formatMobileNumber } from '../../../adapters/utils';


const SelectedRechargeDetails = (props) => {
    let usageQuota;
    const { starterCredit } = props.appData.serviceNumber;

    if (props.plan.usageQuota) {
        usageQuota = (
            <React.Fragment>
                {props.plan.usageQuota.map((quota) => {
                    return (
                        <div role="listitem" key={quota}>
                            <TextStyle className="mt-2 d-flex" alias="FinePrint">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                                {quota}
                            </TextStyle>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    const rechargeHeading = props.actualAmount ? 'Your recharge includes' : 'Your plan includes';
    const discount = props.plan.firstTimeDiscountPercentage || props.plan.firstTimeDiscountPrice;

    return (
        <div tabIndex="-1" className="payment-card" aria-label={`${props.plan.planHeading} plan`}>
            <div>
                <TextStyle element={'h2'} alias="HeadingD">
                    Your mobile number
                </TextStyle>
                <TextStyle className="mt-2" alias="TextBodyShort">
                    {formatMobileNumber(props.msisdn)}
                </TextStyle>
            </div>
            <div className="mt-3">
                <TextStyle element={'h2'} alias="HeadingD">
                    {rechargeHeading}
                </TextStyle>
                <ul role="list" className="custom-list">
                    {props.plan.promotion && props.plan.includedBonus && (
                        <li role="listitem">
                            <TextStyle className="mt-2 bonus-text d-flex" alias="Label">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                                <span>{`${props.plan.includedBonus}`}</span>
                            </TextStyle>
                        </li>
                    )}
                    {discount && (
                        <li role="listitem">
                            <TextStyle className="mt-2 bonus-text d-flex" alias="FinePrint">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                                {discount} {' OFF'}
                            </TextStyle>
                        </li>
                    )}
                    {starterCredit !== 0 && (
                        <li role="listitem">
                            <TextStyle className="mt-2 d-flex" alias="FinePrint">
                                <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />${starterCredit}
                            </TextStyle>
                        </li>
                    )}
                    <li role="listitem">
                        <TextStyle className="mt-2 d-flex" alias="FinePrint">
                            <img className="custom-tick mr-2" aria-hidden="true" src={tick} alt="tick" />
                            {props.plan.planHeading}
                        </TextStyle>
                    </li>
                    {usageQuota}
                    <li role="listitem">
                        <TextStyle className="mt-2 d-flex ml-3" alias="FinePrint">
                            {props.plan.planCondition}
                        </TextStyle>
                    </li>
                    {props.plan.promotion && props.plan.promotionalTemplate && (
                        <li role="listitem">
                            <TextStyle className="mt-2 d-flex ml-3" alias="FinePrint">
                                {props.plan.promotionalTemplate}
                            </TextStyle>
                        </li>
                    )}
                    {props.plan.firstTimeTemplate && (
                        <li role="listitem">
                            <TextStyle className="mt-2 d-flex ml-3" alias="FinePrint">
                                {props.plan.firstTimeTemplate}
                            </TextStyle>
                        </li>
                    )}
                </ul>
            </div>
            {starterCredit === 0 && (
                <div>
                    <hr className="m-3 " />
                    <div className="d-flex info-and-price">
                        <TextStyle className="totalpay order-1" element="span" alias="HeadingD">
                            Recharge Amount
                        </TextStyle>
                        <div>
                            <TextStyle alias="HeadingC">{props.plan.firstTimePrice ? props.plan.firstTimePrice : `$${props.actualAmount}`}</TextStyle>
                            {(props.plan.firstTimeDiscountPrice || props.plan.firstTimeDiscountPercentage) && <TextStyle alias="TextBigBody">Was {props.plan.price}</TextStyle>}
                        </div>
                    </div>
                    <div>
                        <InlineLink>
                            <TextStyle alias="FinePrint">
                                <p><a className="button-as-link" role="button" tabIndex="0" onClick={props.toggleModal}>Payment fees may apply</a></p>
                            </TextStyle>
                        </InlineLink>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectedRechargeDetails;
